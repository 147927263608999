



























































































































































































































































































































































































































































































































































































































































































































































































import Maintenance from '@/components/OdakyuPoint/tabs/PasmoCharge/Maintenance.vue';
import InputNumber from '@/components/InputNumber.vue';
import cls from '@/common/classification';
import { Component, Vue } from 'vue-property-decorator';
import { OpCardList } from '@/models/opcards/OpCard';
import { OpCardRepository } from '@/repositories/OpCardRepository';
import { PasmoChargeLimit } from '@/models/PasmoCharge';
import { PasmoChargeRepository } from '@/repositories/PasmoChargeRepository';
import { ReqSevenbankRegister } from '@/gen/titan/api';
import {
  SEVENBANK_MAINTENANCE_START_HOUR_UTC,
  SEVENBANK_MAINTENANCE_STOP_HOUR_UTC
} from '@/common/constants';
import { mapState } from 'vuex';

@Component({
  components: { Maintenance, InputNumber },
  computed: { ...mapState(['op']) }
})
export default class ApplySevenbankCharge extends Vue {
  // チャージ制限にまつわる情報 ページ初回描画時に初期化される
  chargeLimit: PasmoChargeLimit | null = null;
  // ページ全体のエラーメッセージ
  // OP 残高、今月チャージ可能額の取得に失敗した場合などに値がセットされる
  pageErrMsg = '';
  // フォームのエラーメッセージ
  formErrMsg = '';
  // ユーザが入力するチャージ申請額 (手数料を含まない)
  chargeAmount = 0;
  // 入力欄に表示するためのコンマ付きチャージ申請額の数値文字列
  chargeAmountInput = '';
  // 提携先コード API から固定値が返却される
  partnerCode = '';
  // 確認番号 登録完了時に API から値が返却される
  confNo = '';
  // 利用規約 (Terms os Service; ToS) がチェックされているか
  isTosChecked = false;
  // 確認モーダルを表示するか
  isConfirmationModalOpened = false;
  // 送金登録が完了したか
  isRegistrationCompleted = false;
  // サービス規約のモーダルを表示するか
  isTosModalOpened = false;
  // 処理中フラグ
  // このフラグが立っている場合、送金登録処理を実行しない
  // ボタン連打防止のために使う
  isProcessing = false;
  // ページ表示時刻
  now: Date | null = null;
  // 入力値がチャージ申請ポイントとして正当であるか
  isValidChargeAmount = false;

  created() {
    this.now = new Date();
    this.initialize()
      .then(() => {
        this.validateInitialState();
      })
      .catch(() => {
        this.pageErrMsg = this.$msg.get('2000054');
      });
  }

  initialize() {
    return Promise.all([this.fetchOpBalance(), this.fetchPasmoChargeLimit()]);
  }

  get opCardRepo() {
    return new OpCardRepository();
  }

  get pasmoChargeRepo() {
    return new PasmoChargeRepository();
  }

  get opCards() {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }

  // 現在のOP残高
  // any型で定義されているため、Number型に変換して返す
  get opBalance() {
    return Number(this.$store.state.op.balance.previousBalance);
  }

  // 手数料
  // 読み込まれていない場合は0を返す
  get sevenChargeFee() {
    if (this.chargeLimit) {
      return this.chargeLimit.SevenChargeFee;
    } else {
      return 0;
    }
  }

  // 今月のチャージ可能金額
  // 読み込まれていない場合は0を返す
  get availableChargeAmount() {
    if (this.chargeLimit) {
      return this.chargeLimit.availableChargeAmount;
    } else {
      return 0;
    }
  }

  // チャージ申請後ポイント残高
  get afterChargeOpBalance(): number {
    return this.opBalance - (this.chargeAmount + this.sevenChargeFee);
  }

  get isConfirmationButtonActive(): boolean {
    return (
      this.isTosChecked &&
      !this.pageErrMsg &&
      this.chargeAmount !== 0 &&
      this.isValidChargeAmount
    );
  }

  get isUnderMaintenance(): boolean {
    if (!this.now) {
      return false;
    }

    const hour = this.now.getUTCHours();
    return (
      hour >= SEVENBANK_MAINTENANCE_START_HOUR_UTC &&
      hour < SEVENBANK_MAINTENANCE_STOP_HOUR_UTC
    );
  }

  async fetchPasmoChargeLimit() {
    this.chargeLimit = await this.pasmoChargeRepo.getPasmoChargeLimit();
  }

  async fetchOpBalance() {
    // すでにOP残高を取得している場合、何もしない
    if (
      this.$store.state.op.balance.returnStatus ===
      cls.POINT_DEAL_STATUS_FROM_ECOP.SUCCESS.CD
    ) {
      return;
    }

    const balance = await this.opCardRepo.getOpBalance();
    this.$store.commit('setOpBalance', balance);
  }

  // 初期状態エラーのバリデーションを行う
  validateInitialState() {
    if (this.chargeLimit) {
      // 当月チャージ申請済み金額が既に月額チャージ上限額に到達している場合はエラー
      if (
        this.chargeLimit.monthlyChargeAmount >=
        this.chargeLimit.monthlyChargeLimit
      ) {
        this.pageErrMsg = this.$msg.get('2000055');
      }
      // 処理時点の小田急ポイント残高が、最低チャージ金額 + 手数料 未満である場合はエラー
      if (
        this.opBalance <
        this.chargeLimit.SevenMinimumAmount + this.sevenChargeFee
      ) {
        this.pageErrMsg = this.$msg.get('2000056', {
          num:
            this.chargeLimit.SevenMinimumAmount +
            this.chargeLimit.SevenChargeFee
        });
      }
    }
  }

  // チャージ額バリデーションおよび計算表示更新を行う
  validateInput(): boolean {
    const res = this.inputValidator();
    this.isValidChargeAmount = res;
    return res;
  }

  inputValidator(): boolean {
    this.formErrMsg = '';

    // chargeLimit の null チェック
    // ページの初回描画時に chargeLimit は初期化されるため、通常 chargeLimit が null であることはない
    if (!this.chargeLimit) {
      return false;
    }

    // チャージ金額が入力されていない
    if (!this.chargeAmountInput) {
      this.formErrMsg = this.$msg.get('2000062');
      return false;
    }

    // チャージ金額が半角数字でない
    if (!/^[\d,]*$/.test(this.chargeAmountInput)) {
      this.formErrMsg = this.$msg.get('2000063');
      return false;
    }

    // 入力金額が `this.chargeLimit.SevenMinimumAmount` 円未満
    if (this.chargeAmount < this.chargeLimit.SevenMinimumAmount) {
      this.formErrMsg = this.$msg.get('2000059', {
        num: this.chargeLimit.SevenMinimumAmount
      });
      return false;
    }

    // 入力金額が `this.chargeLimit.monthlyChargeLimit` 円超過
    if (this.chargeAmount > this.chargeLimit.monthlyChargeLimit) {
      this.formErrMsg = this.$msg.get('2000058', {
        num: this.chargeLimit.SevenMinimumAmount
      });
      return false;
    }

    // 入力金額が `this.chargeLimit.SevenUnitAmount` 円単位でない
    if (this.chargeAmount % this.chargeLimit.SevenUnitAmount !== 0) {
      this.formErrMsg = this.$msg.get('2000060', {
        num: this.chargeLimit.SevenUnitAmount
      });
      return false;
    }

    // 「入力金額 + 手数料」が小田急ポイント残高より大きい
    if (this.chargeAmount + this.sevenChargeFee > this.opBalance) {
      this.formErrMsg = this.$msg.get('2000057');
      return false;
    }

    // 入力金額が今月チャージ可能金額より大きい
    if (this.chargeAmount > this.availableChargeAmount) {
      this.formErrMsg = this.$msg.get('2000058');
      return false;
    }

    return true;
  }

  openTosModal() {
    this.isTosModalOpened = true;
  }

  closeTosModal() {
    this.isTosModalOpened = false;
  }

  // フォーム入力内容のバリデーションに成功すればモーダルを開く
  openConfirmationModal() {
    this.isConfirmationModalOpened = this.validateInput();
  }

  closeConfirmationModal() {
    this.isConfirmationModalOpened = false;
  }

  register() {
    // ボタン連打防止のための isProcessing を確認する
    if (this.isProcessing) {
      return;
    }
    this.isProcessing = true;

    // チャージ制限額API取得結果を読み込めていない場合は何もせず終了する
    if (!this.chargeLimit) {
      return;
    }

    const body: ReqSevenbankRegister = {
      odakyu_customer_no: this.opCards.selectMainCard.odakyuCustomerNo,
      balance: this.opBalance,
      mail_address: this.$auth.user.email,
      charge_amount: this.chargeAmount,
      commission: this.chargeLimit.SevenChargeFee
    };
    this.pasmoChargeRepo
      .postPasmoChargeSevenbankRegister(body)
      .then(res => {
        this.partnerCode = res.partnerCode;
        this.confNo = res.confNo;
        this.isRegistrationCompleted = true;
      })
      .then(() => this.opCardRepo.getOpBalance())
      .then(balance => this.$store.commit('setOpBalance', balance))
      .catch(() => {
        this.pageErrMsg = this.$msg.get('2000061');
      })
      .finally(() => {
        this.closeConfirmationModal();
        this.isProcessing = false;
      });
    window.scrollTo(0, 0);
  }
}
